import React from "react";
import theme from "theme";
import { Theme, Link, Image, Section, Text, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Privacy | Finteck
			</title>
			<meta name={"description"} content={"Finteck - Privacy "} />
			<meta property={"og:title"} content={"Privacy | Finteck"} />
			<meta property={"og:description"} content={"Finteck - Privacy "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-works-angle.svg?v=2020-11-06T16:36:54.345Z"} />
			<link rel={"shortcut icon"} href={"https://raw.githubusercontent.com/Finteck-Payment-Labs/resources/main/finteck/favicon.ico"} type={"image/x-icon"} />
		</Helmet>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Link transition="opacity 200ms ease" quarkly-title="Link" href="/" position="relative">
				<Image src="https://raw.githubusercontent.com/Finteck-Payment-Labs/resources/main/finteck/Logo-Full-Black.png" width="120px" z-index="3" />
			</Link>
		</Section>
		<Section
			lg-padding="25px 0 25px 0"
			sm-padding="0px 0 25px 0"
			justify-content="center"
			padding="25px 0 75px 0"
			sm-align-items="center"
			sm-justify-content="center"
			quarkly-title="Hero"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				width="100%"
				background="linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,#191C23 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-works-angle.svg?v=2020-11-06T16:36:54.345Z) center center/cover repeat scroll padding-box"
				height="620px"
				justify-content="center"
				sm-width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				align-items="center"
				lg-height="520px"
				md-height="420px"
				md-padding="0px 24px 0px 24px"
			/>
			<Text
				font="--headline1"
				margin="16px 0px 0px 0px"
				sm-text-align="center"
				sm-width="80%"
				lg-text-align="center"
				lg-font="--headline2"
				color="--light"
			>
				Your Privacy We Care About
			</Text>
			<Text
				sm-text-align="center"
				sm-width="80%"
				opacity="0.7"
				md-text-align="center"
				font="--lead"
				color="--light"
				margin="10px 0px 35px 0px"
			>
				We are ensuring industry level practice to ensure that your data is protected with us.
			</Text>
		</Section>
		<Section>
			<Text margin="0px 0px 0px 0px">
				Privacy Policy for Finteck Payment Labs Private Limited{"\n\n"}
				<br />
				<br />
				At Finteck, accessible from https://www.finteck.in, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Finteck and how we use it.{"\n\n"}
				<br />
				<br />
				If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.{"\n\n"}
				<br />
				<br />
				This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Finteck. This policy is not applicable to any information collected offline or via channels other than this website.
				<br />
				<br />
				Consent{"\n\n"}
				<br />
				<br />
				By using our website, you hereby consent to our Privacy Policy and agree to its terms.{"\n\n"}
				<br />
				<br />
				Information we collect{"\n\n"}
				<br />
				<br />
				The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.{"\n"}
				<br />
				<br />
				If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.{"\n"}
				<br />
				<br />
				When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.{"\n\n"}
				<br />
				<br />
				How we use your information{"\n\n"}
				<br />
				<br />
				We use the information we collect in various ways, including to:{"\n\n"}
				<br />
				<br />
				{"\n"}
				<br />
				<br />
				Provide, operate, and maintain our website{"\n"}
				<br />
				<br />
				Improve, personalize, and expand our website{"\n"}
				<br />
				<br />
				Understand and analyze how you use our website{"\n"}
				<br />
				<br />
				Develop new products, services, features, and functionality{"\n"}
				<br />
				<br />
				Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes{"\n"}
				<br />
				<br />
				Send you emails{"\n"}
				<br />
				<br />
				Find and prevent fraud{"\n\n\n"}
				<br />
				<br />
				Log Files{"\n\n"}
				<br />
				<br />
				Finteck follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.{"\n\n"}
				<br />
				<br />
				Cookies and Web Beacons{"\n\n"}
				<br />
				<br />
				Like any other website, Finteck uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.{"\n\n\n"}
				<br />
				<br />
				Our Advertising Partners{"\n\n"}
				<br />
				<br />
				Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.{"\n\n"}
				<br />
				<br />
				{"\n    "}
				<br />
				<br />
				{"\n        "}
				<br />
				<br />
				Google{"\n        "}
				<br />
				<br />
				<Link
					href="https://policies.google.com/technologies/ads"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					https://policies.google.com/technologies/ads
				</Link>
				{"\n    \n\n\n"}
				<br />
				<br />
				Advertising Partners Privacy Policies{"\n\n"}
				<br />
				<br />
				You may consult this list to find the Privacy Policy for each of the advertising partners of Finteck.{"\n\n"}
				<br />
				<br />
				Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Finteck, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.{"\n\n"}
				<br />
				<br />
				Note that Finteck has no access to or control over these cookies that are used by third-party advertisers.{"\n\n"}
				<br />
				<br />
				Third Party Privacy Policies{"\n\n"}
				<br />
				<br />
				Finteck's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.{" \n\n"}
				<br />
				<br />
				You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.{"\n\n"}
				<br />
				<br />
				CCPA Privacy Rights (Do Not Sell My Personal Information){"\n\n"}
				<br />
				<br />
				Under the CCPA, among other rights, California consumers have the right to:{"\n"}
				<br />
				<br />
				Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.{"\n"}
				<br />
				<br />
				Request that a business delete any personal data about the consumer that a business has collected.{"\n"}
				<br />
				<br />
				Request that a business that sells a consumer's personal data, not sell the consumer's personal data.{"\n"}
				<br />
				<br />
				If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.{"\n\n"}
				<br />
				<br />
				GDPR Data Protection Rights{"\n\n"}
				<br />
				<br />
				We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:{"\n"}
				<br />
				<br />
				The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.{"\n"}
				<br />
				<br />
				The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.{"\n"}
				<br />
				<br />
				The right to erasure – You have the right to request that we erase your personal data, under certain conditions.{"\n"}
				<br />
				<br />
				The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.{"\n"}
				<br />
				<br />
				The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.{"\n"}
				<br />
				<br />
				The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.{"\n"}
				<br />
				<br />
				If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.{"\n\n"}
				<br />
				<br />
				Children's Information{"\n\n"}
				<br />
				<br />
				Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.{"\n\n"}
				<br />
				<br />
				Finteck does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
			</Text>
		</Section>
		<Section
			sm-padding="24px 0 24px 0"
			background="--color-lightD1"
			border-style="solid"
			box-sizing="border-box"
			justify-content="center"
			padding="90px 0px 100px 0px"
			border-color="--color-lightD2"
			border-width="1px 0px 1px 0px"
			lg-padding="50px 30px 50px 30px"
			quarkly-title="FAQ"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 48px 0px 48px"
				md-margin="0px 16px 0px 16px"
				align-items="center"
			/>
			<Text
				font="--base"
				margin="0px 0px 10px 0px"
				text-transform="uppercase"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				letter-spacing="1px"
				color="--dark"
				opacity="0.6"
				text-align="center"
				lg-text-align="center"
			>
				We're here to help
			</Text>
			<Text
				sm-font="--headline3"
				md-font="--headline3"
				font="--headline2"
				margin="0px 0px 64px 0px"
				color="--dark"
				text-align="center"
				lg-text-align="center"
				lg-margin="0px 0px 36px 0px"
			>
				FAQ
			</Text>
			<Box
				width="100%"
				display="grid"
				grid-gap="32px"
				grid-template-columns="repeat(4, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box>
					<Text
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
					>
						Do we need to store data ?
					</Text>
					<Text
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
					>
						Not all your data is stored, but to provide you some functionality, we need to store some data, but we ensure that is in compliance with PCI DSS, GDPR & India's Data Protection Regulations
					</Text>
				</Box>
				<Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
					>
						How to delete my data ?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						We take your data as our topmost priority. You can reach us hello@finteck.in for help regarding such.
					</Text>
				</Box>
				<Box>
					<Text
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
					>
						Is it safe to use our platform ?
					</Text>
					<Text
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
					>
						It is 100% safe to use our platform, as we ensure industry grade safety vault to keep privacy as out topmost priority.
					</Text>
				</Box>
				<Box>
					<Text
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
					>
						Still Have any questions?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						Please feel free to write us on{" "}
						<br />
						hello@finteck.in, we will write you at earliest.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			quarkly-title="USP"
			padding="75px 0 50px 0"
			lg-padding="50px 0 25px 0"
			lg-height="auto"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
			/>
			<Box
				justify-content="center"
				flex-direction="column"
				md-min-height="360px"
				min-height="480px"
				padding="36px 24px 36px 24px"
				align-items="center"
				sm-min-height="280px"
				display="flex"
				background="linear-gradient(180deg,rgba(24, 29, 34, 0.5) 0%,transparent 100%) 0 0 no-repeat,--color-lightD1 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-fireworks.svg?v=2020-11-06T17:22:27.801Z) center center/cover no-repeat"
				lg-min-height="420px"
			>
				<Text
					color="--light"
					md-font="--headline3"
					margin="10px 0px 15px 0px"
					font="--headline2"
					text-align="center"
					quarkly-title="Title"
				>
					#FinteckLife
				</Text>
				<Text
					opacity="0.6"
					text-align="center"
					quarkly-title="Description"
					color="--light"
					max-width="720px"
					margin="0px 0px 28px 0px"
					font="--lead"
				>
					We are here for secure and smooth transactions and transitions. ☺️
				</Text>
			</Box>
		</Section>
		<Section background="--color-darkL2">
			<Section width="50%">
				<Text margin="0px 0px 0px 0px" color="#ffffff">
					Powered By Finteck Payment Labs Private Limited
				</Text>
			</Section>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://finteck.in"}
			target={"_blank"}
		>
			Made with ❤️ by Finteck
		</Link>
	</Theme>;
});