export default {
	"pages": {
		"root": {
			"name": "root",
			"children": [
				"638e6c561d363b0026b348a4",
				"638e6c561d363b0026b348a8"
			],
			"id": "root",
			"pageUrl": "root"
		},
		"638e6c561d363b0026b348a4": {
			"id": "638e6c561d363b0026b348a4",
			"name": "404",
			"pageUrl": "404"
		},
		"638e6c561d363b0026b348a8": {
			"id": "638e6c561d363b0026b348a8",
			"name": "index",
			"pageUrl": "index",
			"seo": {
				"og:description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference.",
				"title": "Home | Website Example",
				"og:title": "Home | Website Example",
				"og:image": "https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z",
				"description": "It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."
			}
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_32px": "https://uploads.quarkly.io/638e6c561d363b0026b3488b/images/favicon.ico?v=2022-12-05T22:42:00.157Z",
			"title": "Privacy Policy - Finteck"
		}
	}
}